"use client"
import { NavLinks } from "./nav-links";
import { NavSearch } from "./nav-search";
import React, { useState, useRef } from 'react';
import Image from "next/image";
import { useIsVisible } from "../hooks/useIsVisible";
//if client use
import { useTranslations } from "next-intl";

export const Services = () => {
  const t = useTranslations("Services");

  const ref1 = useRef<HTMLDivElement>(null);
  const isVisible1 = useIsVisible(ref1);


  const ref2 = useRef<HTMLDivElement>(null);
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef<HTMLDivElement>(null);
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef<HTMLDivElement>(null);
  const isVisible4 = useIsVisible(ref4);


  const ref5 = useRef<HTMLDivElement>(null);
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef<HTMLDivElement>(null);
  const isVisible6 = useIsVisible(ref6);


  return (

<>
<div className="w-screen bg-bitben-violet pt-8" style={{paddingBottom:"8rem"}}>
<div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5 ">
      <div className="col-span-3">
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-center mb-4 text-slate-200">{t("title0")}<span className='font-semibold italic'>{t("title1")}</span>{t("title2")}</h2>
        {/* <p className="text-lg md:text-xl lg:text-2xl text-center text-slate-300">{t("description")}</p> */}
      </div>
    </div>


  <div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5" >
    <div ref={ref1} className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible1 ? "opacity-100" : "opacity-0"}`} >
        <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_customized_websolutions_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_customized_websolutions_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/website-development" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-bitben-green hover:text-bitben-violet">
              {t("service_customized_websolutions_link")}
            </a>
        </div>
    </div>

    <div  
    ref={ref2}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible2 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible1 ? "0.2s" : "0s" }}
    >
         <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_webhosting_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_webhosting_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/webhosting" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_webhosting_link")}
            </a>
        </div>
    </div>


    <div
    ref={ref3}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible3 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible2 ? "0.2s" : "0s" }}
    >
        <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_software_development_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_software_development_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/software-development" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_software_development_link")}
            </a>
        </div>
    </div>
</div>
<div className="grid md:grid-cols-2 max-w-screen-lg mx-auto gap-10 mt-16 px-5">
    {/* <div ref={ref4}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible4 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible3 ? "0.2s" : "0s" }}
>
<span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_api_development_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_api_development_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="#" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
            {t("service_api_development_link")}
            </a>
        </div>
    </div> */}

    <div ref={ref5}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible5 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible4 ? "0.2s" : "0s" }}>
        <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_iot_solutions_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_iot_solutions_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/iot" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_iot_solutions_link")}
            </a>
        </div>
    </div>


    <div ref={ref6}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible6 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible5 ? "0.2s" : "0s" }}>
        <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_ci_design_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_ci_design_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/grafic-design" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
            {t("service_ci_design_link")}
            </a>
        </div>
    </div>
</div>

</div>
</>
  );
};