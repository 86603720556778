import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from '../../../styles/components/BasicSlider.module.scss'
import { useTranslations } from "next-intl";

interface SlideProps {
    imageSrc: string;
    textPart1: string;
    textPart2: string;
    textPart3: string;
    description: string;
    linkText: string;
    linkUrl: string;
  }
  

export const ResponsiveSwiperSlide: React.FC<SlideProps> = ({ imageSrc, textPart1, textPart2, textPart3, description,linkText,linkUrl }) => {
    const t = useTranslations("Pros");

  return (
 
      // <div className="container mx-auto px-6 flex flex-col sm:flex-row py-16 max-w-7xl">
      //   {/* Textbereich */}
      //   <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-3/5 flex flex-col relative z-20">
      //     <span className="w-20 h-2 bg-bitben-violet dark:bg-white mb-12"></span>        
      //     <h1 className="font-bebas-neue uppercase text-5xl sm:text-6xl md:text-8xl font-black flex flex-col leading-none pb-6">
      //       <span className="text-bitben-greener text-border">
      //         {textPart1}
      //       </span>
      //       <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet">
      //         {textPart2}
      //       </span>
      //       <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet inline-block bg-no-repeat" style={{background: 'linear-gradient(120deg, #F9D058 0%, #F9D058 100%)', backgroundSize: '100% 50%; background-position: 0 60%', display: 'inline'}}> 
      //         {textPart3}
      //       </span>
      //       <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet inline-block bg-no-repeat" 
      //         style={{
      //           backgroundImage: 'linear-gradient(120deg, #F9D058 0%, #F9D058 100%)',
      //           backgroundSize: '100% 50%',
      //           backgroundPosition: '0 60%',
      //           display: 'inline'
      //         }}>
      //       {textPart3}
      //     </span>
      //     </h1>
      //     <p className="text-sm sm:text-base text-bitben-violet dark:text-bitben-violet">
      //     {description}
      //     </p>
      //     <div className="flex mt-8">
      //       <a href="#" className="uppercase py-2 px-4 rounded-lg bg-bitben-violet border-2 border-transparent text-white text-md mr-4 hover:bg-bitben-green hover:text-bitben-violet">
      //       {linkText}
      //       </a>
      //     </div>
      //   </div>
      //   {/* Bildbereich */}
      //   <div className="w-full sm:w-1/2 md:w-2/3 lg:w-2/3 xl:w-2/3 relative mt-8 sm:mt-0">
      //     <Image
      //       priority
      //       src={imageSrc}
      //       alt="bitben hero"
      //       layout="responsive"
      //       width={600}
      //       height={400}
      //       style={{ maxWidth: '100%', height: 'auto' }}
      //     />
      //   </div>
      // </div>
      <div className="container mx-auto px-6 flex flex-col sm:flex-row py-16 max-w-7xl">
  {/* Textbereich */}
  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-3/5 flex flex-col relative z-20">
    <span className="w-20 h-2 bg-bitben-violet dark:bg-white mb-12"></span>        
    <h1 className="font-bebas-neue uppercase text-5xl sm:text-6xl md:text-8xl font-black flex flex-col leading-none pb-6">
      <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet text-highlight">
        {textPart1}
      </span>
      <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet">
        {textPart2}
      </span>
      <span className="text-5xl sm:text-6xl md:text-7xl text-bitben-violet text-highlight">
        {textPart3}
      </span>
    </h1>
    <p className="text-sm sm:text-base text-bitben-violet dark:text-bitben-violet">
      {description}
    </p>
    <div className="flex mt-8">
      <a href={linkUrl} className="uppercase py-2 px-4 rounded-lg bg-bitben-violet border-2 border-transparent text-white text-md mr-4 hover:bg-bitben-green hover:text-bitben-violet">
        {linkText}
      </a>
    </div>
  </div>
  {/* Bildbereich */}
  <div className="w-full sm:w-1/2 md:w-2/3 lg:w-2/3 xl:w-2/3 relative mt-8 sm:mt-0">
    <Image
      priority
      src={imageSrc}
      alt="bitben hero"
      layout="responsive"
      width={600}
      height={400}
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</div>


  );
};

