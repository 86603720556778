import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/components/common/BasicSlider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CallToActionBannerWithImage"] */ "/app/src/app/[locale]/components/common/CallToActionBannerWithImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/app/[locale]/components/common/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarFull"] */ "/app/src/app/[locale]/components/common/navbar-dark-light-rtl-ltr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/components/common/Pros.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuickLinkBox"] */ "/app/src/app/[locale]/components/common/QuickLinkBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Services"] */ "/app/src/app/[locale]/components/common/Services.tsx");
