'use client'
import React, { useState, useEffect } from 'react';
import styles from '../../../styles/components/QuickLinkBox.module.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaArrowUp, FaClipboardList, FaTag, FaCalendarAlt, FaUser, FaEnvelope, FaPhone, FaAddressBook, FaCode } from 'react-icons/fa';

interface QuickLinkBoxProps {
  // Falls Props benötigt werden, hier definieren.
}

export const QuickLinkBox: React.FC<QuickLinkBoxProps> = (props) => {
  const [showGoTop, setShowGoTop] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [clickCounts, setClickCounts] = useState<{ [key: string]: number }>({});
  const [lastClickedElementId, setLastClickedElementId] = useState<string>("");
  const [currentlyHoveredButton, setCurrentlyHoveredButton] = useState<string | null>(null);

  const handleTouchStart = (id: string, href: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    let clickHistory = JSON.parse(sessionStorage.getItem('clickHistory') || '[]');

    clickHistory.push({ timestamp: new Date().getTime(), buttonId: id });

    if (clickHistory.length > 2) {
      clickHistory.shift();
    }

    sessionStorage.setItem('clickHistory', JSON.stringify(clickHistory));

    const latestClickedButton = clickHistory[clickHistory.length - 1].buttonId;
    const previousClickedButton = clickHistory.length === 2 ? clickHistory[0].buttonId : "None";

    if (previousClickedButton === id) {
      setClickCounts({});
      window.location.href = href;
    } else {
      const currentCount = clickCounts[id] || 0;
      const updatedCounts = { ...clickCounts, [id]: currentCount + 1 };

      setClickCounts(updatedCounts);

      if (currentCount === 0) {
        setActiveButton(id);

        setTimeout(() => {  
          let clickHistory = JSON.parse(sessionStorage.getItem('clickHistory') || '[]');
      
          if (clickHistory.length > 0) {
              let latestClickedButton = clickHistory[clickHistory.length - 1].buttonId;
              let latestClickedButtonTS = new Date(clickHistory[clickHistory.length - 1].timestamp).getTime();
             
              let previousClickedButton = (clickHistory.length > 1) ? clickHistory[clickHistory.length - 2].buttonId : "None";
              let previousClickedButtonTS = new Date(clickHistory[0].timestamp).getTime();
              
              // Calculate the time delta
              let calculatedTimeDelta = new Date().getTime() - latestClickedButtonTS;
          
              // When to automatically reset the Active Button
              if (calculatedTimeDelta >= 5000) {
                  setActiveButton(null);
                  updatedCounts[id] = 0;
                  setClickCounts(updatedCounts);
              } else if (latestClickedButton !== previousClickedButton) {
                  // Do nothing, condition is satisfied
              } else {
                  setActiveButton(null);
                  updatedCounts[id] = 0;
                  setClickCounts(updatedCounts);
              }
      
              // Update session storage after processing
              clickHistory.shift(); // Now shift the array
              sessionStorage.setItem('clickHistory', JSON.stringify(clickHistory));
          }
      }, 5000); // Reset active button after 5000 ms

        Object.keys(clickCounts).forEach(key => {
          updatedCounts[key] = key === id ? (clickCounts[key] || 0) + 1 : 0;
        });
      } else {
        window.location.href = href;
      }
    }
  };

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 50);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    sessionStorage.setItem('clickHistory', '[]');
    window.addEventListener('scroll', handleVisibleButton);
    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={styles.specialButtons}>
        <a id="services" onClick={handleTouchStart('services', '/services')} className={activeButton === 'services' ? styles.hovered : ''}>
          <FaCode className={styles.theIconItself}></FaCode>
          <i className="icon-katalog"></i> Dienstleistungen
        </a>
        <a id="phone" onClick={handleTouchStart('phone', 'tel:+436602255236')} className={activeButton === 'phone' ? styles.hovered : ''}>          
          <FaPhone className={styles.theIconItself}></FaPhone>
          jetzt Anrufen
        </a>
        <a id="contact" onClick={handleTouchStart('contact', 'mailto:office@bitben.dev')} className={activeButton === 'contact' ? styles.hovered : ''}>          
          <FaEnvelope className={styles.theIconItself}></FaEnvelope>
          EMail schreiben
        </a>
        <a
          href="#top"
          title="Nach Oben"
          className={`${styles.slideUp} ${showGoTop ? styles.showAnker : ''}`}
          onClick={handleScrollUp}
        >
          <FaArrowUp className={styles.theGoTopIcon}></FaArrowUp>
          {/* <FontAwesomeIcon icon="fa-solid fa-arrow-up" className={styles.theGoTopIcon} /> */}
        </a>
      </div>
    </React.Fragment>
  );
};
