"use client"
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Container, Row, Col, Card, Placeholder, Button } from 'react-bootstrap';
// Swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y,Autoplay  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ResponsiveSwiperSlide } from "./ResponsiveSwiperSlide";
// Import Swiper styles
import 'swiper/css/bundle';
//Load Styles
import styles from '../../../styles/components/BasicSlider.module.scss'
//if client use
import { useTranslations } from "next-intl";

import imageSlide0 from "../../assets/grafics/hero-image.svg";
import imageSlide1 from "../../assets/grafics/hero-image.svg";
import imageSlide2 from "../../assets/grafics/hero-image.svg";


function BasicSlider () {  
  const t = useTranslations('HeroLinks');
  const [isLoading, setIsLoading] = useState(false);

  return(
      <div >
         {
            isLoading === false ?  

            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Scrollbar,
                A11y,
                Autoplay
              ]}
              autoplay={{ delay: 8000}}
              loop={true}
              speed={3000}
              // lazy={true}
              slidesPerView={1}
              // navigation
              // pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
                  <SwiperSlide className={styles['swiper-slide']}>
               <ResponsiveSwiperSlide
                  imageSrc={imageSlide0}
                  textPart1={t("ex_1_title_1")}
                  textPart2={t("ex_1_title_2")}
                  textPart3={t("ex_1_title_3")}
                  description={t("ex_1_description")}
                  linkText={t("ex_1_button_text")}
                  linkUrl={t("ex_1_button_url")}
                />
             </SwiperSlide>
             {/* DIY Pakete Slide */}
              {/* <SwiperSlide className={styles['swiper-slide']}>
               <ResponsiveSwiperSlide
                  imageSrc={imageSlide0}
                  textPart1={t("ex_2_title_1")}
                  textPart2={t("ex_2_title_2")}
                  textPart3={t("ex_2_title_3")}
                  description={t("ex_2_description")}
                  linkText={t("ex_2_button_text")}
                  linkUrl={t("ex_2_button_url")}
                />
             </SwiperSlide> */}
            
            </Swiper> 
          :' waiting for Slider data'
          }
      </div>
)
}

export default BasicSlider;